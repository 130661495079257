import React, { FC, useState, useEffect, Dispatch, SetStateAction } from 'react'

import { Box, IconButton, List, ListItem } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { colors } from '@/utils/const'
import { OpportunityMeta } from '@/interfaces'

interface Props {
    meta: OpportunityMeta
    setPageIndex: Dispatch<SetStateAction<number>>
}

export const PaginationComplex: FC<Props> = ({ meta, setPageIndex }) => {
    const [paginationArray, setPaginationArray] = useState([])
    const { last_page, current_page } = meta
        ? meta
        : {
              last_page: null,
              current_page: null,
          }

    useEffect(() => {
        if (last_page > 4) {
            if ([1, 2].includes(current_page)) {
                setPaginationArray([1, 2, 3, 4, 5])
            } else if ([last_page, last_page - 1].includes(current_page)) {
                setPaginationArray([
                    last_page - 4,
                    last_page - 3,
                    last_page - 2,
                    last_page - 1,
                    last_page,
                ])
            } else {
                setPaginationArray([
                    current_page - 2,
                    current_page - 1,
                    current_page,
                    current_page + 1,
                    current_page + 2,
                ])
            }
        } else {
            setPaginationArray(
                [...Array(last_page)].map((item, index) => index + 1),
            )
        }
    }, [meta])

    const handleNext = () => {
        setPageIndex(meta?.current_page + 1)
    }

    const handlePrevious = () => {
        setPageIndex(meta?.current_page - 1)
    }

    return (
        <Box display="flex" justifyContent="end" m={6}>
            <Box display="flex">
                <IconButton
                    aria-label={''}
                    isDisabled={current_page === 1}
                    onClick={handlePrevious}
                    bg={colors['secondary-soft-hover']}
                    color={colors.secondary}
                    cursor="pointer"
                    h={6}
                    icon={<ChevronLeftIcon boxSize={6} />}
                    rounded="full"
                />

                <List
                    bg={colors['secondary-soft-hover']}
                    color={'#6E6B7B'}
                    display={'flex'}
                    fontSize={10}
                    justifyContent="space-between"
                    mx={2}
                    px={4}
                    rounded="full"
                    w="full">
                    {paginationArray.map((item, index) => {
                        if (item === current_page) {
                            return (
                                <ListItem
                                    key={item}
                                    bg={colors.secondary}
                                    color="white"
                                    display="flex"
                                    fontWeight={700}
                                    justifyContent={'center'}
                                    p={1}
                                    rounded={'full'}
                                    w={'24px'}>
                                    {item}
                                </ListItem>
                            )
                        }

                        return (
                            <ListItem
                                cursor="pointer"
                                key={item}
                                p={1}
                                onClick={() => {
                                    setPageIndex(item)
                                }}>
                                {item}
                            </ListItem>
                        )
                    })}
                </List>

                <IconButton
                    aria-label={''}
                    isDisabled={current_page === last_page}
                    onClick={handleNext}
                    bg={colors['secondary-soft-hover']}
                    color={colors.secondary}
                    cursor="pointer"
                    h={6}
                    icon={<ChevronRightIcon boxSize={6} />}
                    rounded="full"
                />
            </Box>
        </Box>
    )
}
