import { FC } from 'react'

interface Props {
    children: string
    className?: string
    type?: 'submit' | 'button' | 'reset'
    onClick?: () => void
}

export const Button: FC<Props> = ({ type = 'submit', className, ...props }) => (
    <button type={type} className={`${className} rounded`} {...props} />
)
