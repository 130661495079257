import { FC } from 'react'

import { HStack, Text } from '@chakra-ui/react'
import { RiInformationLine } from 'react-icons/ri'

interface EmptyStateProps {
    message: string
    fontSize?: string
    iconSize?: string
    minH?: string
    padding?: number
}

export const EmptyState: FC<EmptyStateProps> = ({
    message,
    fontSize = 'sm',
    iconSize = 40,
    minH = '250px',
    padding = 10,
}) => {
    return (
        <HStack w="full" h="full" minH={minH} justify="center" p={padding}>
            <RiInformationLine size={iconSize} />
            <Text mt={'1'} fontSize={fontSize} color={'blackAlpha.800'}>
                {message}
            </Text>
        </HStack>
    )
}
