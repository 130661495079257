import React from 'react'

import { Box, useRadio } from '@chakra-ui/react'
import { colors } from '@/utils/const'

export const RadioCard = ({ ...props }) => {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                borderRadius="md"
                borderWidth="1px"
                cursor="pointer"
                display="flex"
                alignItems="center"
                _checked={{
                    bg: 'white',
                    borderColor: colors.secondary,
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={4}
                py={2}>
                {props.children}
            </Box>
        </Box>
    )
}
