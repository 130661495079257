import React, { FC, useState, useEffect } from 'react'

import { Table } from '@tanstack/react-table'

import { Box, IconButton, List, ListItem } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { colors } from '@/utils/const'

interface Props {
    table: Table<any>
}

export const Pagination: FC<Props> = ({ table }) => {
    const [paginationArray, setPaginationArray] = useState([])

    useEffect(() => {
        if (table.getPageCount() > 4) {
            if ([0, 1].includes(table.getState().pagination.pageIndex)) {
                setPaginationArray([1, 2, 3, 4, 5])
            } else if (
                [table.getPageCount() - 1, table.getPageCount() - 2].includes(
                    table.getState().pagination.pageIndex,
                )
            ) {
                setPaginationArray([
                    table.getPageCount() - 4,
                    table.getPageCount() - 3,
                    table.getPageCount() - 2,
                    table.getPageCount() - 1,
                    table.getPageCount(),
                ])
            } else {
                setPaginationArray([
                    table.getState().pagination.pageIndex - 1,
                    table.getState().pagination.pageIndex,
                    table.getState().pagination.pageIndex + 1,
                    table.getState().pagination.pageIndex + 2,
                    table.getState().pagination.pageIndex + 3,
                ])
            }
        } else {
            setPaginationArray(
                [...Array(table.getPageCount())].map(
                    (item, index) => index + 1,
                ),
            )
        }
    }, [table.getState()])

    return (
        <Box display="flex" justifyContent="end" m={6}>
            <Box display="flex">
                <IconButton
                    aria-label={''}
                    isDisabled={!table.getCanPreviousPage()}
                    onClick={() => table.previousPage()}
                    bg={colors['secondary-soft-hover']}
                    color={colors.secondary}
                    cursor="pointer"
                    h={6}
                    icon={<ChevronLeftIcon boxSize={6} />}
                    rounded="full"
                />

                <List
                    bg={colors['secondary-soft-hover']}
                    color={'#6E6B7B'}
                    display={'flex'}
                    fontSize={10}
                    justifyContent="space-between"
                    mx={2}
                    px={4}
                    rounded="full"
                    w="full">
                    {paginationArray.map((item, index) => {
                        if (
                            item ===
                            table.getState().pagination.pageIndex + 1
                        ) {
                            return (
                                <ListItem
                                    key={item}
                                    bg={colors.secondary}
                                    color="white"
                                    display="flex"
                                    fontWeight={700}
                                    justifyContent={'center'}
                                    p={1}
                                    rounded={'full'}
                                    w={'24px'}>
                                    {item}
                                </ListItem>
                            )
                        }

                        return (
                            <ListItem
                                cursor="pointer"
                                key={item}
                                p={1}
                                onClick={() => {
                                    table.setPageIndex(item - 1)
                                }}>
                                {item}
                            </ListItem>
                        )
                    })}
                </List>

                <IconButton
                    aria-label={''}
                    isDisabled={!table.getCanNextPage()}
                    onClick={() => table.nextPage()}
                    bg={colors['secondary-soft-hover']}
                    color={colors.secondary}
                    cursor="pointer"
                    h={6}
                    icon={<ChevronRightIcon boxSize={6} />}
                    rounded="full"
                />
            </Box>
        </Box>
    )
}
