import { FC } from 'react'

interface Props {
    className?: string
    children: string
    htmlFor: string
}

export const Label: FC<Props> = ({ className, children, htmlFor }) => (
    <label className={`${className}`} htmlFor={htmlFor}>
        {children}
    </label>
)
