import React, { FC } from 'react'

import { Box, Text } from '@chakra-ui/react'

interface Props {
    color: string
    bg: string
    text: string
}

export const CheuronStart: FC<Props> = ({ color, bg, text }) => {
    return (
        <Box display="flex" w="full" h="28px">
            <Box
                bg={bg}
                w="full"
                // minW="93px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                roundedLeft={8}>
                <Text color={color}>{text}</Text>
            </Box>
            <Box
                borderTop={`12px solid transparent`}
                borderBottom={`12px solid transparent`}
                borderLeft={`6px solid ${bg}`}
            />
        </Box>
    )
}
