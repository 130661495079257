import React, { FC, useState } from 'react'

import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    ModalHeader,
    Box,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'

interface Props {
    title: string
    body: string
    note?: string
    isLoading: boolean
    isOpen: boolean
    handleCloseModal: () => void
    handleDelete: () => void
}

export const DeleteModal: FC<Props> = ({
    title,
    body,
    note,
    isLoading,
    isOpen,
    handleCloseModal,
    handleDelete,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    fontSize={20}
                    fontWeight={400}
                    textAlign="center"
                    pt={6}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <Box display="flex" justifyContent="center">
                        <Box w="75%" textAlign="center">
                            <Text
                                color="#565758"
                                fontSize={16}
                                fontWeight={400}
                                mb={4}>
                                {body}
                            </Text>
                        </Box>
                    </Box>
                    {note && (
                        <Box display="flex" justifyContent="center">
                            <Box w="80%">
                                <Text
                                    color={colors['danger-high']}
                                    fontSize={12}
                                    fontWeight={400}
                                    mb={4}>
                                    {note}
                                </Text>
                            </Box>
                        </Box>
                    )}
                </ModalBody>

                <Divider />

                <ModalFooter justifyContent="center" py={4}>
                    <Button
                        bg="white"
                        border="1px"
                        borderColor={colors.secondary}
                        color={colors.secondary}
                        fontSize={14}
                        fontWeight={400}
                        mr={8}
                        h="32px"
                        w={119}
                        onClick={handleCloseModal}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        No
                    </Button>

                    <Button
                        bg={colors.secondary}
                        border="1px"
                        color="white"
                        fontSize={14}
                        isLoading={isLoading}
                        fontWeight={400}
                        h="32px"
                        w={119}
                        onClick={handleDelete}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Sí, eliminalo
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
