import { FC } from 'react'

interface Props {
    autoFocus?: boolean
    autoComplete?: string
    className?: string
    disabled?: boolean
    id?: string
    name?: string
    placeholder?: string
    onChange?: (event: any) => void
    required?: boolean
    type?: string
    value?: string
}

export const InputEntry: FC<Props> = ({
    disabled = false,
    className,
    ...props
}) => (
    <input
        disabled={disabled}
        className={`${className} rounded shadow-sm border-b border-secondary focus:border-secondary-soft-focus focus:ring focus:ring-tertiary focus:ring-opacity-50 text-normal`}
        {...props}
    />
)
