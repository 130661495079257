import React, { FC } from 'react'
import Link from 'next/link'

import { ChevronLeftIcon } from '@chakra-ui/icons'
import { colors } from '@/utils/const'

interface Props {
    className?: string
    href: string
}

export const BackButton: FC<Props> = ({ className, href }) => {
    return (
        <div className={`${className}`}>
            <Link href={href}>
                <ChevronLeftIcon
                    color={`${colors.tertiary}`}
                    className="ring-2 ring-white ring rounded-full cursor-pointer"
                    width={'40px'}
                    height="40px"
                />
            </Link>
        </div>
    )
}
