import React, { useState, ChangeEvent } from 'react'

import { Switch as SwitchCH } from '@chakra-ui/react'

export const Switch = () => {
    const [checked, setChecked] = useState(true)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }
    return (
        <SwitchCH
            onChange={handleChange}
            checked={checked}
            colorScheme="messenger"
        />
    )
}
