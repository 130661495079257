import React, { FC } from 'react'

import { Box, Text } from '@chakra-ui/react'

interface Props {
    color: string
    bg: string
    text: string
    handleClick: () => void
}

export const CheuronEnd: FC<Props> = ({ color, bg, text, handleClick }) => {
    return (
        <Box display="flex" w="full" h="28px">
            <Box
                borderTop={`12px solid ${bg}`}
                borderBottom={`12px solid ${bg}`}
                borderRight={`6px solid ${bg}`}
                borderLeft={`6px solid transparent`}
            />
            <Box
                bg={bg}
                w="full"
                display="flex"
                justifyContent="center"
                cursor="pointer"
                onClick={handleClick}
                alignItems="center"
                roundedRight={8}>
                <Text color={color}>{text}</Text>
            </Box>
        </Box>
    )
}
